// src/ConvergenceTable.js

import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

const ConvergenceTable = ({ title, data, columns }) => {
  // State for search term
  const [searchTerm, setSearchTerm] = useState('');

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter data based on search term
  const filteredData = data.filter((item) =>
    columns.some((col) => {
      const value = item[col.field];
      return value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    })
  );

  // Prepare CSV headers
  const csvHeaders = columns.map((col) => ({
    label: col.header,
    key: col.field,
  }));

  // Handle no data scenario
  if (!data || data.length === 0) {
    return (
      <div className="text-center mt-4">
        <p>No data available for {title}.</p>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <h2 className="text-center mb-4">{title}</h2>
      <div className="d-flex justify-content-between mb-3">
        {/* Search Input */}
        <input
          type="text"
          className="form-control w-50"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
        {/* CSV Download Button */}
        <CSVLink
          data={data}
          headers={csvHeaders}
          filename={`${title.replace(/\s+/g, '_').toLowerCase()}.csv`}
          className="btn btn-primary"
        >
          Download Report
        </CSVLink>
      </div>
      {/* Table */}
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <thead className="thead-dark text-center">
            <tr>
              {columns.map((col, index) => (
                <th scope="col" key={index}>
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => {
                  const cellValue = item[col.field];
                  const displayValue =
                    col.format && cellValue !== null && cellValue !== undefined
                      ? col.format(cellValue)
                      : cellValue ?? 'N/A';
                  return <td key={colIndex}>{displayValue}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConvergenceTable;
