import React from 'react'
import ImageSlider from './ImageSlider'
import KeyCards from './Card/KeyCards'
import Gallary from './Gallary'
import AboutUs from './AboutUs/AboutUs'

function Home() {
  return (
    <div>
      <ImageSlider/>
      <KeyCards/>
      <Gallary/>
      <AboutUs/>
    </div>
  )
}

export default Home
