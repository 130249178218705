import React from 'react';
import NgoTables from './ngoTables';

function Partnerships() {
  return (
    <div>
      <NgoTables/>
    </div>
  )
}

export default Partnerships
