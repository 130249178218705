import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Use Routes instead of Switch
import TopBar from './Components/Topbar/Topbar';
import TopBelow from './Components/Topbar/TopBelow';
import { ThemeProvider } from './Screens/ThemeContext'; // Import ThemeProvider
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Landless from './Components/DepartmentalStatastics/Landless/Landless';
import CapacityBuilding from './Components/DepartmentalStatastics/CapacityBuilding/CapacityBuilding';
import Convergence from './Components/DepartmentalStatastics/Convergence/Convergence';

import Innovations from './Components/DepartmentalStatastics/Innovations/Innovations';
import IEC from './Components/DepartmentalStatastics/IEC/iec';
import Partnerships from './Components/DepartmentalStatastics/Partnership/Partnerships';
import Footer from './Components/Footer/Footer';



function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <div>
          <TopBar />
          <TopBelow />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/landless" element={<Landless />} />
            <Route path="/Capacity-building" element={<CapacityBuilding />} />
            <Route path="/convergence" element={<Convergence />} />
            <Route path="/innovations" element={<Innovations />} />
            <Route path="/iec" element={<IEC />} />
            <Route path="/partnerships" element={<Partnerships />} />
          </Routes>
          <Footer/>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
