import React, { useState } from 'react';
import { FaHome, FaBars, FaTimes, FaUserAlt, FaInfoCircle, FaUtensils } from 'react-icons/fa';
import { FaCamera } from 'react-icons/fa';
import './Navbar.css'; // Import the new CSS styles

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <a href="/" className="logo"> </a>
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <a href="/" className="menu-item "><FaHome className="icon" /> Home</a>
        <a href="#gallary" className="menu-item"><FaCamera className="icon" /> Gallery</a>        <a href="#" className="menu-item"><FaUserAlt className="icon" /> Login</a>
        <a href="#aboutus" className="menu-item"><FaInfoCircle className="icon" /> About Us</a>
      </div>
    </nav>
  );
};

export default Navbar;
