import React from 'react'
import Bahumajali from './Bahumajali'

function Innovations() {
  return (
    <div>
      <Bahumajali/>
    </div>
  )
}

export default Innovations
