import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import axios from 'axios';

const Landless = () => {
  // Existing state variables
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDistricts, setExpandedDistricts] = useState({});
  const [expandedTalukas, setExpandedTalukas] = useState({});
  const [paginatedData, setPaginatedData] = useState([]);
  const [paginatedLoading, setPaginatedLoading] = useState(true);
  const [paginatedError, setPaginatedError] = useState(null);
  const [paginatedSearchTerm, setPaginatedSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  // Fetch hierarchical data
  useEffect(() => {
    const fetchHierarchicalData = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/reports/scheme1A/district-response');
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const result = await response.json();
        setData(result[0]?.scheme1Response || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchHierarchicalData();
  }, []);

  // Fetch paginated data
  useEffect(() => {
    const fetchPaginatedData = async () => {
      setPaginatedLoading(true);
      try {
        const response = await axios.get('http://145.223.19.41:8080/mahaAawaasReport/api/reports/landless', {
          params: { page: currentPage, size: 10 },
        });
        setPaginatedData(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        setPaginatedError(err);
      } finally {
        setPaginatedLoading(false);
      }
    };
    fetchPaginatedData();
  }, [currentPage]);

  // Functions for managing state
  const handleDistrictClick = (districtName) => {
    setExpandedDistricts((prev) => ({ ...prev, [districtName]: !prev[districtName] }));
  };

  const handleTalukaClick = (talukaId) => {
    setExpandedTalukas((prev) => ({ ...prev, [talukaId]: !prev[talukaId] }));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePaginatedSearch = (event) => {
    setPaginatedSearchTerm(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Rendering functions
  const renderVillages = (villages) => {
    return villages.map((village) => (
      <tr key={`village-${village.villageId}`}>
        <td></td>
        <td></td>
        <td style={{ paddingLeft: '40px' }}>{village.villageName}</td>
        <td className="text-right">{village.totalLandless ?? 'N/A'}</td>
        <td className="text-right">{village.landProvided ?? 'N/A'}</td>
        <td className="text-right">{village.remaining ?? 'N/A'}</td>
        <td className="text-right">{village.percentage != null ? village.percentage.toFixed(2) : 'N/A'}</td>
      </tr>
    ));
  };

  const renderTalukas = (talukas) => {
    return talukas.map((taluka) => (
      <React.Fragment key={`taluka-${taluka.talukaId}`}>
        <tr onClick={() => handleTalukaClick(taluka.talukaId)} style={{ cursor: 'pointer' }}>
          <td></td>
          <td style={{ paddingLeft: '20px' }}>
            {expandedTalukas[taluka.talukaId] ? '▼' : '►'} {taluka.talukaName}
          </td>
          <td className="text-right">{taluka.totalLandless ?? 'N/A'}</td>
          <td className="text-right">{taluka.landProvided ?? 'N/A'}</td>
          <td className="text-right">{taluka.remaining ?? 'N/A'}</td>
          <td className="text-right">{taluka.percentage != null ? taluka.percentage.toFixed(2) : 'N/A'}</td>
        </tr>
        {expandedTalukas[taluka.talukaId] && renderVillages(taluka.villages)}
      </React.Fragment>
    ));
  };

  const renderDistricts = () => {
    return data
      .filter((district) => {
        const combinedData = `${district.districtName ?? ''} ${district.totalLandless ?? ''} ${district.landProvided ?? ''} ${district.remaining ?? ''} ${district.percentage ?? ''}`;
        return combinedData.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .map((district) => (
        <React.Fragment key={`district-${district.districtName}`}>
          <tr onClick={() => handleDistrictClick(district.districtName)} style={{ cursor: 'pointer', backgroundColor: '#f9f9f9' }}>
            <td></td>
            <td>{expandedDistricts[district.districtName] ? '▼' : '►'} {district.districtName}</td>
            <td className="text-right">{district.totalLandless ?? 'N/A'}</td>
            <td className="text-right">{district.landProvided ?? 'N/A'}</td>
            <td className="text-right">{district.remaining ?? 'N/A'}</td>
            <td className="text-right">{district.percentage != null ? district.percentage.toFixed(2) : 'N/A'}</td>
          </tr>
          {expandedDistricts[district.districtName] && renderTalukas(district.talukas)}
        </React.Fragment>
      ));
  };

  const filteredPaginatedData = paginatedData.filter((row) => {
    const combinedData = `${row.benificiaryName ?? ''} ${row.fatherName ?? ''} ${row.registrationNumber ?? ''} ${row.status ?? ''} ${row.category ?? ''} ${row.scheme ?? ''} ${row.districtName ?? ''} ${row.talukaName ?? ''} ${row.villageName ?? ''}`;
    return combinedData.toLowerCase().includes(paginatedSearchTerm.toLowerCase());
  });

  const csvHeaders = [
    { label: 'Beneficiary Name', key: 'benificiaryName' },
    { label: 'Father Name', key: 'fatherName' },
    { label: 'Registration Number', key: 'registrationNumber' },
    { label: 'Status', key: 'status' },
    { label: 'Category', key: 'category' },
    { label: 'Scheme', key: 'scheme' },
    { label: 'District Name', key: 'districtName' },
    { label: 'Taluka Name', key: 'talukaName' },
    { label: 'Village Name', key: 'villageName' },
  ];

  if (loading || paginatedLoading) {
    return (
      <div className="d-flex justify-content-center mt-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error || paginatedError) {
    return (
      <div className="alert alert-danger text-center mt-4" role="alert">
        Error fetching data: {error?.message || paginatedError?.message}
      </div>
    );
  }

  if (data.length === 0 && paginatedData.length === 0) {
    return (
      <div className="text-center mt-4">
        <p>No data available.</p>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
      </div>

      {/* Hierarchical Data Table */}
      <h2 className="text-center mb-4">Landless Data - Scheme 1A (प्रधानमंत्री आवास योजना - ग्रामीण)</h2>
      <input type="text" className="form-control mb-3" placeholder="Search..." value={searchTerm} onChange={handleSearch} />
      <div className="table-responsive mb-5">
        <table className="table table-striped table-bordered table-hover">
          <caption>List of landless data</caption>
          <thead className="thead-dark text-center">
            <tr>
              <th scope="col"></th>
              <th scope="col">District / Taluka / Village Name</th>
              <th scope="col">Total Landless</th>
              <th scope="col">Land Provided</th>
              <th scope="col">Remaining</th>
              <th scope="col">Percentage (%)</th>
            </tr>
          </thead>
          <tbody>{renderDistricts()}</tbody>
        </table>
      </div>

      {/* Paginated Data Table */}
      <h2 className="text-center mb-4">All Landless Data</h2>
      <div className="d-flex justify-content-between mb-3">
        <input type="text" className="form-control" placeholder="Search..." value={paginatedSearchTerm} onChange={handlePaginatedSearch} style={{ maxWidth: '300px' }} />
        <CSVLink data={filteredPaginatedData} headers={csvHeaders} filename="landless_data.csv" className="btn btn-primary">Download CSV</CSVLink>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-hover">
          <caption>List of all landless data</caption>
          <thead className="thead-dark text-center">
            <tr>
              <th scope="col">Beneficiary Name</th>
              <th scope="col">Father Name</th>
              <th scope="col">Registration Number</th>
              <th scope="col">Status</th>
              <th scope="col">Category</th>
              <th scope="col">Scheme</th>
              <th scope="col">District Name</th>
              <th scope="col">Taluka Name</th>
              <th scope="col">Village Name</th>
            </tr>
          </thead>
          <tbody>
            {filteredPaginatedData.map((row, index) => (
              <tr key={index}>
                <td>{row.benificiaryName ?? 'N/A'}</td>
                <td>{row.fatherName ?? 'N/A'}</td>
                <td>{row.registrationNumber ?? 'N/A'}</td>
                <td>{row.status ?? 'N/A'}</td>
                <td>{row.category ?? 'N/A'}</td>
                <td>{row.scheme ?? 'N/A'}</td>
                <td>{row.districtName ?? 'N/A'}</td>
                <td>{row.talukaName ?? 'N/A'}</td>
                <td>{row.villageName ?? 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button className="btn btn-secondary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>Previous</button>
        <span>Page {currentPage + 1} of {totalPages}</span>
        <button className="btn btn-secondary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage + 1 >= totalPages}>Next</button>
      </div>
    </div>
  );
};

export default Landless;
