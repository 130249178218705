import React, { useState } from 'react';
import { Modal, Carousel, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gallary.css';

const Gallery = () => {
  // State for image modal
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // State for video modal
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  // Sample images and YouTube embed videos
  const imageUrls = [
    // Add 100 image URLs here (example placeholders)
    'https://i.ytimg.com/vi/biyWH_MSi-M/hq720.jpg',
    'https://bcsportal.com/wp-content/uploads/2024/01/Blue-Andamans-Vlog-YouTube-Thumbnail-20-1024x576.jpg',
    'https://media.assettype.com/agrowon%2F2022-11%2F3b405f1b-dbe4-42cd-8e8e-99ff84322fc4%2FNews_Story__82_.png',
    'https://i.ytimg.com/vi/biyWH_MSi-M/hq720.jpg',
    // Repeat or add more image URLs as needed
  ];

  const videoUrls = [
    'https://www.youtube.com/embed/7mbqXecYfHk',  // Video 1
    'https://www.youtube.com/embed/D6UNlK0KaP0',  // Video 2
    'https://www.youtube.com/embed/OdnB3ubNNqc',  // Video 3
    'https://www.youtube.com/embed/biyWH_MSi-M',  // Video 4
  ];

  // Handlers to show modals
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowImageModal(true);
  };

  const handleVideoClick = (url) => {
    setCurrentVideoUrl(url);
    setShowVideoModal(true);
  };

  return (
    <div className="container mt-5" id='gallary'>
      <div className="row">
        <h3 className="mb-4">Image Gallery</h3>
        {/* Only show the first 3 images */}
        {imageUrls.slice(0, 3).map((url, index) => (
          <div key={index} className="col-md-4 mb-4">
            <Card className="image-card" onClick={() => handleImageClick(index)}>
              <Card.Img variant="top" src={url} alt={`Gallery Image ${index}`} />
            </Card>
          </div>
        ))}
      </div>

      <div className="row mt-5">
        <h3 className="mb-4">Video Gallery</h3>
        {videoUrls.slice(0, 3).map((url, index) => (
          <div key={index} className="col-md-4 mb-4">
            <Card className="video-card">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                width="360" height="215"
                  className="embed-responsive-item"
                  src={url}
                  frameBorder="0"
                  allowFullScreen
                  title={`YouTube Video ${index + 1}`}
                ></iframe>
              </div>
            </Card>
          </div>
        ))}
      </div>

      {/* Image Slider Modal */}
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Slider</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display all images in the carousel */}
          <Carousel activeIndex={currentImageIndex} onSelect={(selectedIndex) => setCurrentImageIndex(selectedIndex)}>
            {imageUrls.map((url, index) => (
              <Carousel.Item key={index}>
                <img src={url} alt={`Slide ${index}`} className="d-block w-100" />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      {/* Video Modal */}
      <Modal show={showVideoModal} onHide={() => setShowVideoModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Video Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              width="100%"
              height="400"
              src={currentVideoUrl}
              frameBorder="0"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Gallery;
