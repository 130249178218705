import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faSun, faMoon, faTextHeight, faTextWidth } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../../Screens/ThemeContext';
import './Topbar.css';

const Topbar = () => {
  const { theme, toggleTheme, textSize, changeTextSize } = useContext(ThemeContext);

  // State to hold the current date and time
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Effect to update the date and time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Formatting options for the date
  const dateOptions = { weekday: 'long', month: 'long', day: 'numeric' };
  const formattedDate = currentDateTime.toLocaleDateString(undefined, dateOptions);

  // Formatting options for the time
  const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const formattedTime = currentDateTime.toLocaleTimeString(undefined, timeOptions);

  return (
    <div className={`topbar-area ${theme} ${textSize}`}>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 col-md-7">
            <div className="topbar-menu">
              <ul className="d-flex list-unstyled mb-0">
                <li className='mx-5'><a href="http://145.223.19.41:8081/maa/login">Member Login</a></li>
                {/* <li><a href="#">Sitemap</a></li> */}
                <li className='ml-5'><a href='#main-content'>Skip To Main Contents</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 text-md-right text-center">
            <div className="topbar-social d-flex justify-content-end align-items-center">
              <div className="topbar-date d-none d-lg-inline-block mr-3">
                <i className="fa fa-calendar"></i> {formattedDate}, {formattedTime}
              </div>
              <ul className="social-area d-flex list-unstyled mb-0">
                <li><a className="social-icon facebook-icon" href="https://www.facebook.com/mahaawaas/?ref=embed_page&checkpoint_src=any"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a className="social-icon twitter-icon" href="https://x.com/i/flow/login?redirect_after_login=%2FAwaasMaha"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a className="social-icon youtube-icon" href="https://www.youtube.com/@mahaawaas1952"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a className="social-icon instagram-icon" href="https://www.instagram.com/p/Cz777mAqSsc/?utm_source=ig_embed&ig_rid=fe09336e-e454-4757-8e44-817499772d7e"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
              {/* <div className="day-night-toggle ml-3">
                <button className="mode-toggle-btn" onClick={toggleTheme}>
                  <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} />
                </button>
              </div>
              <div className="text-size-toggle ml-3">
                <button className="text-size-btn" onClick={() => changeTextSize('large')}>
                  <FontAwesomeIcon icon={faTextHeight} /> A+
                </button>
                <button className="text-size-btn" onClick={() => changeTextSize('default')}>
                  A
                </button>
                <button className="text-size-btn" onClick={() => changeTextSize('small')}>
                  <FontAwesomeIcon icon={faTextWidth} /> A-
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
