// AboutUs.js
import React from 'react';
import { motion } from 'framer-motion';
import './AboutUs.css'; // Ensure you have your styles here

const AboutUs = () => {
  return (
    <section id="aboutus">
      <div className="container">
        <motion.div
          className="about-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="row">
            <div className="col-md-12 m-5">
              <div className="section-header">
                <h2>About Us: State Management Unit – Rural Housing (SMU-RH)</h2>
              </div>
            </div>
          </div>
          <div className="row m-2">
            <motion.div
              className="col-md-4"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="section ml-5 mb-5">
                <h3>Our Mission:</h3>
                <p>
                  At SMU-RH, we are committed to ensuring access to housing for all citizens by the year 2024. To achieve this goal, we collaborate closely with various departments and agencies to implement a range of housing schemes, thereby contributing to the socio-economic development of rural communities across Maharashtra.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="col-md-4"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <div className="section">
                <h3>Our Schemes:</h3>
                <ul>
                  <li>Pradhan Mantri Awaas Yojna – Grameen (Central Government Scheme, Government of India)</li>
                  <li>Ramai Awas Yojana (Social Justice Department, Government of Maharashtra)</li>
                  <li>Shabari Awas Yojana (Tribal Development Department, Government of Maharashtra)</li>
                </ul>
              </div>
            </motion.div>
            <motion.div
              className="col-md-4"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <div className="section">
                <h3>Our Role:</h3>
                <ol>
                  <li>Monitoring and Evaluation</li>
                  <li>Coordination</li>
                  <li>Capacity Building</li>
                  <li>Information, Education, and Communication (IEC)</li>
                  <li>Policy Input</li>
                </ol>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
