import React, { createContext, useState } from 'react';

// Create the ThemeContext
export const ThemeContext = createContext();

// Create the ThemeProvider component
export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState('light'); // Default theme
    const [textSize, setTextSize] = useState('default'); // Default text size

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const changeTextSize = (size) => {
        setTextSize(size);
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme, textSize, changeTextSize }}>
            {children}
        </ThemeContext.Provider>
    );
};
