import React, { useState, useEffect } from 'react';
import Icon1 from './SchemeIcons/landless.png';
import Icon2 from './SchemeIcons/meeting.png';
import Icon3 from './SchemeIcons/convergence.png';
import Icon4 from './SchemeIcons/innovation.png';
import Icon5 from './SchemeIcons/iec.png';
import Icon6 from './SchemeIcons/meeting.png';
import { Link } from 'react-router-dom';
import './KeyCards.css';

const KeyCards = () => {
  const [counts, setCounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/dashboard/cardCounts');
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setCounts(data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <section id="main-content">
      <div className="department">
        <div className="container text-center">
          <h2>Progress Of Maha Awaas Abhiyan </h2>
          {/* <p className="subheading">In Maharashtra</p> */}
          <div className="row d-flex justify-content-center mt-5">
            {/* LANDLESS CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
              <div className="squre_1">
                <img className="logoo" src={Icon1} alt="Landless" />
                <h3 className="modern-heading mb-3">
                  <span>LANDLESS</span>
                </h3>
                <h6 className="custom-heading">
                  <span>Total Landless: {counts?.landlessCount?.[0]?.totalLandless ?? 'N/A'}</span>
                </h6>
                <h6 className="custom-heading">
                  Remaining Landless: {counts?.landlessCount?.[0]?.remainingLandless ?? 'N/A'}
                </h6>
                <h6 className="custom-heading">
                  Land Provided Till Date: {counts?.landlessCount?.[0]?.totalLandProvided ?? 'N/A'}
                </h6>
                <Link to="/landless">
                  <button>
                    <div>View More</div>
                    <i className="fas fa-angle-double-right"></i>
                  </button>
                </Link>
              </div>
            </div>

            {/* CAPACITY BUILDING CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
              <div className="squre_2">
                <img className="logoo" src={Icon2} alt="Capacity Building" />
                <h3 className="modern-heading mb-3">
                  <span>CAPACITY BUILDING</span>
                </h3>
                <h6 className="custom-heading">
                  <span>Number Of Training: {counts?.capacityCount?.[0]?.numberOfTraining ?? 'N/A'}</span>
                </h6>
                <h6 className="custom-heading">
                  Workshop: {counts?.capacityCount?.[0]?.workshop ?? 'N/A'}
                </h6>
                <h6 className="custom-heading">
                  Beneficiary Meet: {counts?.capacityCount?.[0]?.beneficiaryMeet ?? 'N/A'}
                </h6>
                <Link to="/capacity-building">
                  <button>
                    <div>View More</div>
                    <i className="fas fa-angle-double-right"></i>
                  </button>
                </Link>
              </div>
            </div>

            {/* CONVERGENCE CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
              <div className="squre_3">
                <img className="logoo" src={Icon3} alt="Convergence" />
                <h3 className="modern-heading mb-3">
                  <span>CONVERGENCE</span>
                </h3>
                <h6 className="custom-heading">
                  <span>
                    स्वच्छ भारत अभियान: {counts?.convergenceCount?.[0]?.swachhBharat ?? 'N/A'}
                  </span>
                </h6>
                <h6 className="custom-heading">
                  जल जीवन मिशन: {counts?.convergenceCount?.[0]?.jalJeevan ?? 'N/A'}
                </h6>
                <h6 className="custom-heading">
                  उज्ज्वला योजना: {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'}
                </h6>
                <Link to="/convergence">
                  <button>
                    <div>View More</div>
                    <i className="fas fa-angle-double-right"></i>
                  </button>
                </Link>
              </div>
            </div>

            {/* INNOVATIONS CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="squre_4">
                <img className="logoo" src={Icon4} alt="Innovations" />
                <h3 className="modern-heading mb-3">
                  <span>INNOVATIONS</span>
                </h3>
                <h6 className="custom-heading">
                  <span>
                    बहुमजली इमारत: {counts?.innovationCount?.[0]?.bahumajliImarati ?? 'N/A'}
                  </span>
                </h6>
                <h6 className="custom-heading">
                  गृहसंकुले: {counts?.innovationCount?.[0]?.gruhsankule ?? 'N/A'}
                </h6>
                <h6 className="custom-heading">
                  ग्रीन हाऊस: {counts?.innovationCount?.[0]?.gruhkarj ?? 'N/A'}
                </h6>
                <Link to="/innovations">
                  <button>
                    <div>View More</div>
                    <i className="fas fa-angle-double-right"></i>
                  </button>
                </Link>
              </div>
            </div>

            {/* I.E.C CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="squre_5">
                <img className="logoo" src={Icon5} alt="I.E.C" />
                <h3 className="modern-heading mb-3">
                  <span>I.E.C</span>
                </h3>
                <h6 className="custom-heading">
                  <span>
                    PRINT MEDIA: {counts?.iecCount?.[0]?.printMedia ?? 'N/A'}
                  </span>
                </h6>
                <h6 className="custom-heading">
                  OUTDOOR MEDIA: {counts?.iecCount?.[0]?.outdoorMedia ?? 'N/A'}
                </h6>
                <h6 className="custom-heading">
                  PUBLICATIONS: {counts?.iecCount?.[0]?.publications ?? 'N/A'}
                </h6>
                <Link to="/iec">
                  <button>
                    <div>Visit Now</div>
                    <i className="fas fa-angle-double-right"></i>
                  </button>
                </Link>
              </div>
            </div>

            {/* PARTNERSHIPS CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="squre_6">
                <img className="logoo" src={Icon6} alt="Partnerships" />
                <h3 className="modern-heading mb-3">
                  <span>PARTNERSHIPS</span>
                </h3>
                <h6 className="custom-heading">
                  <span>
                    ORGANIZATIONS: {counts?.partnershipCount?.[0]?.organizations ?? 'N/A'}
                  </span>
                </h6>
                <h6 className="custom-heading">
                  CORPORATE: {counts?.partnershipCount?.[0]?.corporate ?? 'N/A'}
                </h6>
                <h6 className="custom-heading">
                  ACADEMIA: {counts?.partnershipCount?.[0]?.academia ?? 'N/A'}
                </h6>
                <Link to="/partnerships">
                  <button>
                    <div>Visit Now</div>
                    <i className="fas fa-angle-double-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyCards;
