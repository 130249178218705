import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ConvergenceTable from './ConvergenceTable';

const Convergence = () => {
  const navigate = useNavigate();

  // Ujjwala Yojana Data
  const [ujjwalaData, setUjjwalaData] = useState([]);
  const [ujjwalaLoading, setUjjwalaLoading] = useState(true);
  const [ujjwalaError, setUjjwalaError] = useState(null);

  // Thakkar Bappa Data
  const [thakkarData, setThakkarData] = useState([]);
  const [thakkarLoading, setThakkarLoading] = useState(true);
  const [thakkarError, setThakkarError] = useState(null);

  // Swachh Bharat Data
  const [swachhBharatData, setSwachhBharatData] = useState([]);
  const [swachhBharatLoading, setSwachhBharatLoading] = useState(true);
  const [swachhBharatError, setSwachhBharatError] = useState(null);

  // Saubhagya Yojana Data
  const [saubhagyaData, setSaubhagyaData] = useState([]);
  const [saubhagyaLoading, setSaubhagyaLoading] = useState(true);
  const [saubhagyaError, setSaubhagyaError] = useState(null);

  // Fetch Ujjwala Yojana Data
  useEffect(() => {
    const fetchUjjwalaData = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/convergence/ujjwalaYojana');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Ujjwala Yojana data:', data);
        setUjjwalaData(data);
      } catch (err) {
        console.error('Error fetching Ujjwala Yojana data:', err);
        setUjjwalaError(err);
      } finally {
        setUjjwalaLoading(false);
      }
    };

    fetchUjjwalaData();
  }, []);

  // Fetch Thakkar Bappa Data
  useEffect(() => {
    const fetchThakkarData = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/convergence/thakkarBappa');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Thakkar Bappa data:', data);
        setThakkarData(data);
      } catch (err) {
        console.error('Error fetching Thakkar Bappa data:', err);
        setThakkarError(err);
      } finally {
        setThakkarLoading(false);
      }
    };

    fetchThakkarData();
  }, []);

  // Fetch Swachh Bharat Data
  useEffect(() => {
    const fetchSwachhBharatData = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/convergence/swachhBharat');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Swachh Bharat data:', data);
        setSwachhBharatData(data);
      } catch (err) {
        console.error('Error fetching Swachh Bharat data:', err);
        setSwachhBharatError(err);
      } finally {
        setSwachhBharatLoading(false);
      }
    };

    fetchSwachhBharatData();
  }, []);

  // Fetch Saubhagya Yojana Data
  useEffect(() => {
    const fetchSaubhagyaData = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/convergence/saubhagyaYojana');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Saubhagya Yojana data:', data);
        setSaubhagyaData(data);
      } catch (err) {
        console.error('Error fetching Saubhagya Yojana data:', err);
        setSaubhagyaError(err);
      } finally {
        setSaubhagyaLoading(false);
      }
    };

    fetchSaubhagyaData();
  }, []);

  // Handle Loading and Error States
  if (ujjwalaLoading || thakkarLoading || swachhBharatLoading || saubhagyaLoading) {
    return (
      <div className="d-flex justify-content-center mt-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (ujjwalaError || thakkarError || swachhBharatError || saubhagyaError) {
    return (
      <div className="alert alert-danger text-center mt-4" role="alert">
        Error fetching data:
        {ujjwalaError?.message ||
          thakkarError?.message ||
          swachhBharatError?.message ||
          saubhagyaError?.message}
      </div>
    );
  }

  // Define Columns
  const ujjwalaColumns = [
    { header: 'District Name', field: 'districtName' },
    { header: 'Taluka Name', field: 'talukaName' },
    { header: 'All Gharkul Rajya Yojana', field: 'allgharkulRajyaYojana' },
    { header: 'Total Number', field: 'totalNumber' },
    { header: 'Created Datetime', field: 'createdDatetime' },
    { header: 'Active', field: 'active', format: (value) => (value ? 'Yes' : 'No') },
  ];

  const thakkarColumns = [
    { header: 'District Name', field: 'districtName' },
    { header: 'Taluka Name', field: 'talukaName' },
    { header: 'All Gharkul Rajya Yojana', field: 'allgharkulRajyaYojana' },
    { header: 'Total Number', field: 'totalNumber' },
    { header: 'Labh Swaroop', field: 'labhSwaroop' },
    { header: 'Created Datetime', field: 'createdDatetime' },
    { header: 'Active', field: 'active', format: (value) => (value ? 'Yes' : 'No') },
  ];

  const swachhBharatColumns = [
    { header: 'District Name', field: 'districtName' },
    { header: 'Taluka Name', field: 'talukaName' },
    { header: 'All Gharkul Rajya Yojana', field: 'allgharkulRajyaYojana' },
    { header: 'Total Number', field: 'totalNumber' },
    { header: 'Created Datetime', field: 'createdDatetime' },
    { header: 'Active', field: 'active', format: (value) => (value ? 'Yes' : 'No') },
    { header: 'Swachh Bharat Abhiyan ID', field: 'sbharatAbhiyanId' },
  ];

  const saubhagyaColumns = [
    { header: 'District Name', field: 'districtName' },
    { header: 'Taluka Name', field: 'talukaName' },
    { header: 'All Gharkul Rajya Yojana', field: 'allgharkulRajyaYojana' },
    { header: 'Total Number', field: 'totalNumber' },
    { header: 'Created Datetime', field: 'createdDatetime' },
    { header: 'Active', field: 'active', format: (value) => (value ? 'Yes' : 'No') },
    { header: 'Saubhagya Yojana ID', field: 'saubhagyaYojanaId' },
  ];

  return (
    <div className="container mt-4">
      <button className="btn btn-secondary mb-3" onClick={() => navigate(-1)}>
        Back
      </button>
      <div className="row">
        <div className="col-md-12">
          <ConvergenceTable
            title="Convergence - Ujjwala Yojana"
            data={ujjwalaData}
            columns={ujjwalaColumns}
          />
        </div>
        <div className="col-md-12">
          <ConvergenceTable
            title="Convergence - Thakkar Bappa"
            data={thakkarData}
            columns={thakkarColumns}
          />
        </div>
        <div className="col-md-12">
          <ConvergenceTable
            title="Convergence - Swachh Bharat"
            data={swachhBharatData}
            columns={swachhBharatColumns}
          />
        </div>
        <div className="col-md-12">
          <ConvergenceTable
            title="Convergence - Saubhagya Yojana"
            data={saubhagyaData}
            columns={saubhagyaColumns}
          />
        </div>
        {/* Add more tables as needed */}
      </div>
    </div>
  );
};

export default Convergence;
