import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import './CapacityBuilding.css';

// Helper function to fetch data from the API
const fetchData = async () => {
  try {
    const response = await fetch(`http://145.223.19.41:8080/mahaAawaasReport/api/capacityBuilding/training/taluka-records`);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data.data.content;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const CapacityBuildingTraining = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');

  useEffect(() => {
    const getData = async () => {
      const responseData = await fetchData();
      setData(responseData);
      setFilteredData(responseData);
      setLoading(false);
    };
    getData();
  }, []);

  const districts = data && Array.isArray(data) ? [...new Set(data.map(item => item.districtName))] : [];
  const talukas = district ? [...new Set(data.filter(item => item.districtName === district).map(item => item.talukaName))] : [];

  const filterData = useCallback(() => {
    setFilteredData(data.filter(item => {
      return (
        (!searchTerm || item.trainingMahiti.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!district || item.districtName === district) &&
        (!taluka || item.talukaName === taluka)
      );
    }));
  }, [data, searchTerm, district, taluka]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Training Data');
    XLSX.writeFile(wb, 'Training_Data.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map(item => [
      item.trainingMahiti,
      item.trainingDate,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.villageName || 'N/A',
      item.active ? 'Active' : 'Inactive'
    ]);

    doc.setFont("helvetica", "normal");
    doc.autoTable({
      head: [['Training Information', 'Training Date', 'District', 'Taluka', 'Village', 'Status']],
      body: tableData,
      styles: { fontSize: 10, cellPadding: 2, valign: 'middle' },
      headStyles: { fillColor: [100, 150, 255], fontSize: 12, textColor: 255 },
      margin: { top: 20, left: 10, right: 10 }
    });

    doc.save('Training_Data.pdf');
  };

  return (
    <Container fluid className="mx-2">
      <Row>
        <Col md={2} className="sidebar p-4">
          <div className="d-flex align-items-center mb-4">
            <FaFilter className="sidebar-icon me-2" />
            <h4 className="sidebar-title">Filters</h4>
          </div>
          <Form>
            <Form.Group controlId="districtSelect">
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                className="filter-select"
              >
                <option value="">Select District</option>
                {districts.length > 0 ? (
                  districts.map((districtName, index) => (
                    <option key={index} value={districtName}>{districtName}</option>
                  ))
                ) : (
                  <option>No districts available</option>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="talukaSelect">
              <Form.Label>Taluka</Form.Label>
              <Form.Control
                as="select"
                value={taluka}
                onChange={(e) => setTaluka(e.target.value)}
                className="filter-select"
                disabled={!district}
              >
                <option value="">Select Taluka</option>
                {talukas.length > 0 ? (
                  talukas.map((talukaName, index) => (
                    <option key={index} value={talukaName}>{talukaName}</option>
                  ))
                ) : (
                  <option>No talukas available</option>
                )}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>

        <Col md={10} className="p-4">
          <div className="d-flex justify-content-between mb-4">
            <div className="align-items-center"></div>
            <div className="d-flex align-items-center">
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search Training"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="Search"
                />
                <InputGroup.Text><FaSearch /></InputGroup.Text>
              </InputGroup>
              <Button variant="primary" onClick={exportToExcel} className="ms-2">Export to Excel</Button>
              <Button variant="success" onClick={exportToPDF} className="ms-2">Export to PDF</Button>
            </div>
          </div>

          {loading ? (
            <div className="d-flex justify-content-center">
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
                <div>Loading...</div>
              </div>
            </div>
          ) : (
            <Table className="custom-table table-bordered">
              <thead className='table-bordered'>
                <tr>
                  <th>Training Info</th>
                  <th>Training Date</th>
                  <th>District</th>
                  <th>Taluka</th>
                  <th>Village</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.trainingMahiti}</td>
                      <td>{item.trainingDate}</td>
                      <td>{item.districtName || 'N/A'}</td>
                      <td>{item.talukaName || 'N/A'}</td>
                      <td>{item.villageName || 'N/A'}</td>
                      <td>{item.active ? 'Active' : 'Inactive'}</td>
                    </tr>
                  ))
                ) : (
                  <tr><td colSpan="6" className="no-records">No records available</td></tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CapacityBuildingTraining;
