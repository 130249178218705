import React from 'react'
import CapacityBuildingTraining from './CapacityBuildingTraining'

function CapacityBuilding() {
  return (
    <div>
      <CapacityBuildingTraining/>
    </div>
  )
}

export default CapacityBuilding
