import React, { useState, useEffect, useCallback } from 'react';
import { Table, Pagination, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import './NgoPartnership.css';

// Helper function to fetch data from the API
const fetchData = async (page, size) => {
  try {
    const response = await fetch(`http://145.223.19.41:8080/mahaAawaasReport/api/partnership/ngo/taluka-details?page=${page}&size=${size}`);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return { data: [], totalPages: 0 }; // Returning default structure in case of error
  }
};

const NgoPartnership = () => {
  const [data, setData] = useState([]); // All data
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Items per page
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [searchTerm, setSearchTerm] = useState(''); // Search term

  // State for filters
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');

  // Fetch data on component mount or when page changes
  useEffect(() => {
    const getData = async () => {
      const response = await fetchData(currentPage - 1, itemsPerPage);
      setData(response.data);
      setTotalPages(response.totalPages); // Update total pages
      setFilteredData(response.data); // Initially show all data
      setLoading(false);
    };

    getData();
  }, [currentPage]);

  // Extract districts and talukas
  const districts = [...new Set(data.map(item => item.districtName))];
  const talukas = district ? [...new Set(data.filter(item => item.districtName === district).map(item => item.talukaName))] : [];

  // Filter data based on selected filters and search term
  const filterData = useCallback(() => {
    let filtered = data;

    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter(item => {
        const ngoName = item.ngoName ? item.ngoName.toLowerCase() : '';
        const ngoMahiti = item.ngoMahiti ? item.ngoMahiti.toLowerCase() : '';
        return ngoName.includes(searchTerm.toLowerCase()) || ngoMahiti.includes(searchTerm.toLowerCase());
      });
    }

    // Apply filters for district and taluka
    if (district) filtered = filtered.filter(item => item.districtName === district);
    if (taluka) filtered = filtered.filter(item => item.talukaName === taluka);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to page 1 when filters change
  }, [data, searchTerm, district, taluka]);

  // Run the filter whenever a dependency changes
  useEffect(() => {
    filterData();
  }, [filterData]);

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Export to Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'NGO Data');
    XLSX.writeFile(wb, 'NGO_Data.xlsx');
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map(item => [
      item.ngoId,
      item.ngoName,
      item.ngoMahiti,
      item.fromDate,
      item.toDate,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.active ? 'Active' : 'Inactive'
    ]);

    doc.autoTable({
      head: [['NGO ID', 'NGO Name', 'NGO Mahiti', 'From Date', 'To Date', 'District', 'Taluka', 'Status']],
      body: tableData,
    });

    doc.save('NGO_Data.pdf');
  };

  return (
    <Container fluid className="mx-2">
      <Row>
        {/* Sidebar for Filters */}
        <Col md={2} className="sidebar p-3">
          <div className="d-flex align-items-center mb-4">
            <FaFilter className="sidebar-icon me-2" />
            <h4 className="sidebar-title">Filters</h4>
          </div>
          <Form>
            {/* District Filter */}
            <Form.Group controlId="districtSelect">
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                className="filter-select"
              >
                <option value="">Select District</option>
                {districts.map((districtName, index) => (
                  <option key={index} value={districtName}>{districtName}</option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Taluka Filter */}
            <Form.Group controlId="talukaSelect">
              <Form.Label>Taluka</Form.Label>
              <Form.Control
                as="select"
                value={taluka}
                onChange={(e) => setTaluka(e.target.value)}
                className="filter-select"
                disabled={!district}
              >
                <option value="">Select Taluka</option>
                {talukas.map((talukaName, index) => (
                  <option key={index} value={talukaName}>{talukaName}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>

        {/* Main Table */}
        <Col md={10} className="p-3">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <h4 className="m-0 text-uppercase fw-bold">NGO Partnership Details</h4>
            </div>
            <div className="d-flex align-items-center">
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="Search"
                />
                <InputGroup.Text><FaSearch /></InputGroup.Text>
              </InputGroup>
              <Button variant="success" onClick={exportToExcel} className="ms-2">Export to Excel</Button>
              <Button variant="danger" onClick={exportToPDF} className="ms-2">Export to PDF</Button>
            </div>
          </div>

          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Table striped bordered hover responsive className="custom-table">
              <thead className="table-dark">
                <tr>
                  <th>NGO ID</th>
                  <th>NGO Name</th>
                  <th>NGO Mahiti</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>District</th>
                  <th>Taluka</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={item.ngoId}>
                    <td>{item.ngoId}</td>
                    <td>{item.ngoName}</td>
                    <td>{item.ngoMahiti}</td>
                    <td>{item.fromDate}</td>
                    <td>{item.toDate}</td>
                    <td>{item.districtName || 'N/A'}</td>
                    <td>{item.talukaName || 'N/A'}</td>
                    <td>{item.active ? 'Active' : 'Inactive'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {/* Pagination Controls */}
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePagination(currentPage - 1)}
            />
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePagination(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePagination(currentPage + 1)}
            />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default NgoPartnership;
