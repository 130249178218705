import React, { useState, useEffect, useCallback } from 'react';
import { Table, Pagination, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import './Bahumajali.css';

// Helper function to fetch data from your API
const fetchData = async () => {
  try {
    const response = await fetch('http://145.223.19.41:8080/mahaAawaasReport/api/bahumajli-imarti/findAll');
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const Bahumajali = () => {
  const [data, setData] = useState([]); // All data
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [searchTerm, setSearchTerm] = useState(''); // Search term

  // State for filters
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');
  const [village, setVillage] = useState('');
  const [abhiyanId, setAbhiyanId] = useState('');

  // Fetch data from API on component mount
  useEffect(() => {
    const getData = async () => {
      const dataFromApi = await fetchData();
      setData(dataFromApi);
      setFilteredData(dataFromApi); // Initially show all data
      setLoading(false);
    };

    getData();
  }, []);

  // Abhiyan Names Map
  const abhiyanNames = {
    '1': 'अभियान-1',
    '2': 'अभियान-2',
    '3': 'अभियान-3',
    '4': 'अभियान-4'
  };

  // Extract districts and related data
  const districts = [...new Set(data.map(item => item.districtName))];
  const abhiyanIds = ['1', '2', '3', '4'];

  const talukas = district ? [...new Set(data.filter(item => item.districtName === district).map(item => item.talukaName))] : [];
  const villages = taluka ? [...new Set(data.filter(item => item.talukaName === taluka).map(item => item.villageName))] : [];

  // Split comma-separated values into arrays
  const splitCommaSeparatedValues = useCallback((value) => {
    return value ? value.split(',').map(item => item.trim()) : [];
  }, []);

  // Filter data based on selected filters and search term
  const filterData = useCallback(() => {
    let filtered = data;

    // Apply search term filter
    if (searchTerm) {
      filtered = filtered.filter(item => {
        const fullName = item.fullName ? item.fullName.toLowerCase() : '';
        const beneficiaryId = item.beneficiaryId ? item.beneficiaryId.toString().toLowerCase() : '';
        const districtName = item.districtName ? item.districtName.toLowerCase() : '';
        const talukaName = item.talukaName ? item.talukaName.toLowerCase() : '';
        const villageName = item.villageName ? item.villageName.toLowerCase() : '';

        return fullName.includes(searchTerm.toLowerCase()) ||
               beneficiaryId.includes(searchTerm.toLowerCase()) ||
               districtName.includes(searchTerm.toLowerCase()) ||
               talukaName.includes(searchTerm.toLowerCase()) ||
               villageName.includes(searchTerm.toLowerCase());
      });
    }

    // Apply filter for Abhiyan ID, district, taluka, and village
    if (abhiyanId) filtered = filtered.filter(item => item.abhiyanId.toString() === abhiyanId);
    if (district) filtered = filtered.filter(item => item.districtName === district);
    if (taluka) filtered = filtered.filter(item => item.talukaName === taluka);
    if (village) filtered = filtered.filter(item => item.villageName === village);

    setFilteredData(filtered);
    setCurrentPage(1); // Reset to page 1 when filters change
  }, [data, searchTerm, abhiyanId, district, taluka, village]);

  // Run the filter whenever a dependency changes
  useEffect(() => {
    filterData();
  }, [filterData]);

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Export to Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Beneficiary Data');
    XLSX.writeFile(wb, 'Beneficiary_Data.xlsx');
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map(item => [
      item.bahumajliImaratId,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.villageName || 'N/A',
      item.fullName,
      item.beneficiaryId,
      item.category,
      abhiyanNames[item.abhiyanId] || 'N/A'
    ]);

    doc.autoTable({
      head: [['Serial No.', 'District', 'Taluka', 'Village', 'Full Name', 'Beneficiary ID', 'Category', 'Abhiyan Name']],
      body: tableData,
    });

    doc.save('Beneficiary_Data.pdf');
  };

  return (
    <Container fluid className='mx-2'>
      <Row>
        {/* Sidebar for Filters */}
        <Col md={2} className="sidebar p-3">
          <div className="d-flex align-items-center mb-4">
            <FaFilter className="sidebar-icon me-2" />
            <h4 className="sidebar-title">Filters</h4>
          </div>
          <Form>
            {/* Abhiyan ID Filter */}
            <Form.Group controlId="abhiyanSelect">
              <Form.Label>Abhiyan ID</Form.Label>
              <Form.Control
                as="select"
                value={abhiyanId}
                onChange={(e) => setAbhiyanId(e.target.value)}
                className="filter-select"
              >
                <option value="">Select Abhiyan</option>
                {abhiyanIds.map((id, index) => (
                  <option key={index} value={id}>{`अभियान - ${id}`}</option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* District Filter */}
            <Form.Group controlId="districtSelect">
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                className="filter-select"
              >
                <option value="">Select District</option>
                {districts.map((districtName, index) => (
                  <option key={index} value={districtName}>{districtName}</option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Taluka Filter */}
            <Form.Group controlId="talukaSelect">
              <Form.Label>Taluka</Form.Label>
              <Form.Control
                as="select"
                value={taluka}
                onChange={(e) => setTaluka(e.target.value)}
                className="filter-select"
                disabled={!district}
              >
                <option value="">Select Taluka</option>
                {talukas.map((talukaName, index) => (
                  <option key={index} value={talukaName}>{talukaName}</option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Village Filter */}
            <Form.Group controlId="villageSelect">
              <Form.Label>Village</Form.Label>
              <Form.Control
                as="select"
                value={village}
                onChange={(e) => setVillage(e.target.value)}
                className="filter-select"
                disabled={!taluka}
              >
                <option value="">Select Village</option>
                {villages.map((villageName, index) => (
                  <option key={index} value={villageName}>{villageName}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>

        {/* Main Table */}
        <Col md={10} className="p-3">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <h4 className="m-0 text-uppercase fw-bold">Beneficiary List</h4>
            </div>
            <div className="d-flex align-items-center">
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="Search"
                />
                <InputGroup.Text><FaSearch /></InputGroup.Text>
              </InputGroup>
              <Button variant="success" onClick={exportToExcel} className="ms-2">Export to Excel</Button>
              <Button variant="danger" onClick={exportToPDF} className="ms-2">Export to PDF</Button>
            </div>
          </div>

          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Table striped bordered hover responsive className="custom-table">
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Abhiyan Name</th>
                  <th>District</th>
                  <th>Taluka</th>
                  <th>Village</th>
                  <th>Full Name</th>
                  <th>Beneficiary ID</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => {
                  // Handle missing or null values and provide fallback values (e.g., "N/A")
                  const abhiyanName = item.abhiyanName || 'N/A';
                  const districtName = item.districtName || 'N/A';
                  const talukaName = item.talukaName || 'N/A';
                  const villageName = item.villageName || 'N/A';
                  const fullName = item.fullName || 'N/A'; // Assuming fullName is directly in the item
                  const beneficiaryId = item.beneficiaryId || 'N/A';
                  const category = item.category || 'N/A';

                  // Split the comma-separated values for names, beneficiaryIds, and categories
                  const fullNames = splitCommaSeparatedValues(fullName);
                  const beneficiaryIds = splitCommaSeparatedValues(beneficiaryId);
                  const categories = splitCommaSeparatedValues(category);

                  const maxLength = Math.max(fullNames.length, beneficiaryIds.length, categories.length);

                  // Map over the maximum length of split values
                  return [...Array(maxLength)].map((_, idx) => (
                    <tr key={`${index}-${idx}`}>
                      <td>{startIndex + index + idx + 1}</td> {/* Ensure serial number is unique */}
                      <td>{abhiyanNames[item.abhiyanId] || 'N/A'}</td>
                      <td>{districtName}</td>
                      <td>{talukaName}</td>
                      <td>{villageName}</td>
                      <td>{fullNames[idx] || 'N/A'}</td>
                      <td>{beneficiaryIds[idx] || 'N/A'}</td>
                      <td>{categories[idx] || 'N/A'}</td>
                    </tr>
                  ));
                })}
              </tbody>
            </Table>
          )}

          {/* Pagination Controls */}
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePagination(currentPage - 1)}
            />
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePagination(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePagination(currentPage + 1)}
            />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default Bahumajali;
