import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './TopBelow.css'; 
import emblame from './img/emb.png';
import maha_emb from './img/maha-emb.png';
import logo3 from './img/logo.jpeg';

const TopBelow = () => {
  return (
    <Link to="/" className="mid-wrapper header-bg"> {/* Wrap with Link */}
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-4 col-xs-6 text-center">
            <div className="rotating-image">
              <img 
                src={emblame}
                width="110" 
                height="110" 
                alt="national emblem" 
              />
            </div>
          </div>

          {/* Center text */}
          <div className="col-md-6 col-sm-4 col-xs-6 text-center">
            <div className="emblem-text text-center">
              <span className="main-title">महाआवास अभियान</span>                
              <span className="sub-title">GOVERNMENT OF INDIA</span>
            </div>
          </div>

          {/* Right side logos */}
          <div className="col-md-3 col-sm-4 col-xs-6 text-center">
            <img 
              alt="Maha Awaas Emblem" 
              src={maha_emb}
              className="side-logo"
            />
            <img 
              src={logo3} 
              alt="Pmayg logo" 
              className="side-logo"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TopBelow;
