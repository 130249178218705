import React from 'react';
import Slider from 'react-slick';
import './ImageSlider.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import img1 from './Images/cara01.jpg';
// import img2 from './Images/cara02.jpg';
// import img3 from './Images/cara03.jpg';
// import img4 from './Images/cara04.jpg';
import img5 from './Images/cara05.jpg';
import img6 from './Images/cara06.jpg';

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 200, // Smooth transition speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true, // Fade effect on slide change
    arrows: true,
    pauseOnHover: true,
    cssEase: "ease-in-out", // Smooth transition easing
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div className="slide">
          <img src={img1} alt="Image 1" className="slide-image"/>
          {/* <div className="caption">
            <h2>Beautiful Car #1</h2>
            <p>A sleek and stylish car design.</p>
          </div> */}
        </div>
        <div className="slide">
          <img src={img5} alt="Image 5" className="slide-image"/>
        </div>
        <div className="slide">
          <img src={img6} alt="Image 5" className="slide-image"/>
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
